import { Text, Flex } from "@chakra-ui/react";
import * as React from "react";

const BasePricingTile = ({
  name,
  description,
  logo,
  isHighlighted,
  children,
}) => (
  <Flex
    direction="column"
    p="12"
    bg="gray.100"
    maxW="410px"
    height={isHighlighted ? "unset" : "100%"}
    bgGradient={
      isHighlighted
        ? "linear-gradient(161.1deg, #CDF7FF 12.75%, #7BDBEE 102.89%), #2BA530"
        : "none"
    }
  >
    <Flex direction="row" align="center">
      {logo}
      <Text
        fontWeight="bold"
        fontSize="2xl"
        ml="18px"
        color={isHighlighted ? "cyan.900" : "unset"}
      >
        {name}
      </Text>
    </Flex>
    <Text color={isHighlighted ? "cyan.800" : "gray.600"} mt="3">
      {description}
    </Text>
    {children}
  </Flex>
);

export default BasePricingTile;
