import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  Stack,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react";
import InputField from "../shared/Form/InputField/InputField";
import { ERROR_MESSAGES } from "../../utils/constants";

const BaseTalkToSpecialistModal = ({ isOpen, onClose, isSales }) => {
  const toast = useToast();
  
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur" | "onChange" | "onSubmit",
  });
  const [loading, setLoading] = useState(false);
  const [afterRequest, setAfterRequest] = useState(false);

  const initialRef = React.useRef();
  
  const handleFormSubmit = data => {
    setLoading(true);
    setAfterRequest(false);
    const reqBody = {
      ...data,
      message: `Message sent via Talk to our ${isSales ? "Sales" : "Specialist"} modal`,
    };

    setTimeout(() => {
      fetch(process.env.GATSBY_API_URL + "/landing/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      })
        .then(response => {
          if (response.ok) {
            setLoading(false);
            setAfterRequest(true);
            setTimeout(() => {
              setAfterRequest(false);
              reset();
            }, 3000);
            toast({
              title: "Message sent",
              status: "success",
              duration: "3000",
              isClosable: false,
              position: "top",
            });
          } else {
            setLoading(false);
            setAfterRequest(true);
            setTimeout(() => {
              setAfterRequest(false);
              reset();
            }, 3000);
            toast({
              title: "Something went wrong",
              description: "Please try again later",
              status: "error",
              duration: "3000",
              isClosable: false,
              position: "top",
            });
          }
        })
        .catch(e => {
          setLoading(false);
          setAfterRequest(true);
          console.error(e.message);
          setTimeout(() => {
            setAfterRequest(false);
            reset();
          }, 3000);
          toast({
            title: "Something went wrong",
            description: "Please try again later",
            status: "error",
            duration: "3000",
            isClosable: false,
            position: "top",
          });
        });
    }, 1500);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      initialFocusRef={initialRef}
    >
      <ModalOverlay zIndex="1101" />
      <ModalContent>
        <ModalBody>
          <ModalCloseButton top="24px" right="24px" />
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <VStack spacing="6" alignItems="left">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontWeight="bold" fontSize="xl" alignSelf="left">
                  Talk to our {isSales ? "Sales" : "Specialist"}
                </Text>
              </Flex>
              <Text>
                Leave your contact information and our{" "}
                {isSales ? "Sales" : "Specialist"} will reach out to you soon.
              </Text>
              <Stack spacing="4">
                <InputField
                  aria="Name"
                  label="Your name"
                  placeholder="Enter your name"
                  name="name"
                  error={errors.name}
                  type="text"
                  {...register("name", {
                    required: {
                      value: true,
                      message: ERROR_MESSAGES.REQUIRED,
                    },
                    minLength: {
                      value: 3,
                      message: ERROR_MESSAGES.MIN_LENGTH(3),
                    },
                    maxLength: {
                      value: 50,
                      message: ERROR_MESSAGES.MAX_LENGTH(50),
                    },
                  })}
                />
                <InputField
                  aria="Email"
                  label="E-mail"
                  placeholder="Enter e-mail"
                  name="email"
                  type="email"
                  error={errors.email}
                  {...register("email", {
                    required: {
                      value: true,
                      message: ERROR_MESSAGES.REQUIRED,
                    },
                    minLength: {
                      value: 4,
                      message: ERROR_MESSAGES.MIN_LENGTH(4),
                    },
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: ERROR_MESSAGES.EMAIL,
                    },
                  })}
                />
              </Stack>
              <Flex justifyContent="end">
                <Button
                  isLoading={loading}
                  loadingText="Sending"
                  type="submit"
                  size="lg"
                  disabled={loading || afterRequest}
                >
                  Contact our {isSales ? "Sales" : "Specialist"}
                </Button>
              </Flex>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BaseTalkToSpecialistModal;
