import {
  Box,
  Button,
  Text,
  Switch,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import gsap from "gsap";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo/Seo";
import PricingTile from "../components/PricingTile/PricingTile";
import BasePricingTile from "../components/PricingTile/BasePricingTile";
import AppLogoIcon from "../images/vector/app_logo.svg";
import AppLogoGrayIcon from "../images/vector/app_logo_gray.svg";
import AppLogoCyanIcon from "../images/vector/app_logo_cyan.svg";
import BaseTalkToSpecialistModal from "../components/TalkToSpecialistModal/BaseTalkToSpecialistModal";

const PricingPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paidAnnually, setPaidAnnually] = React.useState(true);

  const pricingRef = React.useRef(null);

  React.useEffect(() => {
    const pricingTileElements =
      pricingRef.current.querySelectorAll(".pricing-tile");
    const mainElements = pricingRef.current.querySelectorAll(".main");

    gsap
      .timeline({ defaults: { delay: 0.5 } })
      .set(pricingRef.current, { opacity: 1 })
      .from(
        mainElements,
        {
          opacity: 0,
          y: 50,
          duration: 0.6,
          stagger: 0.3,
        },
        0.3,
      )
      .from(
        pricingTileElements,
        {
          opacity: 0,
          x: 50,
          duration: 0.6,
          stagger: 0.3,
        },
        0.6,
      );
  }, []);

  return (
    <>
      <Layout>
        <Seo />
        <Box
          ref={pricingRef}
          pt={{ base: "72px", "3xl": "80px" }}
          px={{ base: "6", md: "10", lg: "20", "3xl": "40" }}
          overflow="hidden"
          width="100%"
          gridColumn="1/-1"
          position="relative"
        >
          <Flex className="main" direction="column" align="center" mt="20">
            <Text color="cyan.400" fontSize="xl" fontWeight="bold">
              Pricing
            </Text>
            <Text align="center" fontSize="4xl" fontWeight="bold" mt="10">
              Reach your prospects with Motar.me
            </Text>
            <Text color="gray.500" fontSize="lg" mt="0">
              Select the best plan for you
            </Text>
            <Button
              as="a"
              href={process.env.GATSBY_APP_URL + "/register"}
              target="_blank"
              rel="noopener noreferrer"
              mt="6"
            >
              Start Your 14 Day Free Trial
            </Button>
            <Flex direction="row" align="center" mt="10">
              <Text fontSize="lg" mr="17px">
                Monthly
              </Text>
              <Switch
                variant="pricing"
                size="xl"
                defaultChecked={true}
                onChange={({ target: { checked } }) => setPaidAnnually(checked)}
              />
              <Text fontSize="lg" ml="17px">
                Annually
              </Text>
            </Flex>
            <Grid
              my="12"
              templateColumns={{ base: "1fr", xl: "repeat(3, 1fr)" }}
              gap="6"
              direction="column"
              maxWidth="1280px"
            >
              <GridItem
                className="pricing-tile"
                mt={{ base: "0", xl: "12" }}
                justifyItems="end"
              >
                <PricingTile
                  name="Starter"
                  description="Level up your sales team with Email Automation"
                  price={0}
                  priceDescription="/per user for 2 weeks"
                  logo={<AppLogoGrayIcon />}
                />
              </GridItem>
              <GridItem className="pricing-tile" justifyItems="center">
                <PricingTile
                  name="Professional"
                  description="Level up your sales team with Email Automation"
                  logo={<AppLogoIcon />}
                  price={paidAnnually ? 45 : 55}
                  priceDescription="/per user per month"
                  isHighlighted={true}
                />
              </GridItem>
              <GridItem
                className="pricing-tile"
                mt={{ base: "0", xl: "12" }}
                justifyItems="start"
              >
                <BasePricingTile
                  name="Enterprise"
                  description="To find out more"
                  logo={<AppLogoCyanIcon />}
                >
                  <Button
                    onClick={onOpen}
                    colorScheme="cyan"
                    variant="outline"
                    mt="6"
                  >
                    Talk to Sales
                  </Button>
                </BasePricingTile>
              </GridItem>
            </Grid>
          </Flex>
        </Box>
      </Layout>
      <BaseTalkToSpecialistModal
        isOpen={isOpen}
        onClose={onClose}
        isSales={true}
      />
    </>
  );
};

export default PricingPage;
