import { Button, Text, List, ListItem, ListIcon, Flex } from "@chakra-ui/react";
import * as React from "react";
import CheckCircleIcon from "../../images/vector/check_circle.svg";
import BasePricingTile from "./BasePricingTile";

const PricingTile = ({
  logo,
  name,
  description,
  isHighlighted,
  price,
  priceDescription,
}) => {
  const featureList = [
    "Microsoft Integration",
    "Gmail Integration",
    "Unlimited Contacts",
    "Unlimited Campaigns",
    "Email Sequencing",
    "Email Scheduling",
    "Email Templates",
    "Email Reporting",
    "Email Open Tracking",
    "Email Support",
  ];

  return (
    <BasePricingTile
      logo={logo}
      name={name}
      description={description}
      isHighlighted={isHighlighted}
    >
      <Flex direction="row" align="baseline" mt="6" gap="8px">
        <Text
          color={isHighlighted ? "cyan.400" : "gray.600"}
          fontSize="54px"
          fontWeight="bold"
          lineHeight="66px"
        >
          €{price}
        </Text>
        <Text
          color={isHighlighted ? "cyan.800" : "gray.400"}
          fontSize="xl"
          lineHeight="22px"
          fontWeight="medium"
        >
          {priceDescription}
        </Text>
      </Flex>
      <Text
        color={isHighlighted ? "cyan.900" : "gray.600"}
        fontSize="lg"
        fontWeight="bold"
        mt="6"
      >
        What's included
      </Text>
      <List spacing={4} my="6">
        {featureList.map(feature => (
          <ListItem
            color={isHighlighted ? "cyan.800" : "gray.400"}
            lineHeight="24px"
            key={feature}
          >
            <Flex>
              <ListIcon fontSize="2xl" as={CheckCircleIcon} />
              {feature}
            </Flex>
          </ListItem>
        ))}
      </List>
      <Button
        as="a"
        href={process.env.GATSBY_APP_URL + "/register"}
        color={isHighlighted && "white"}
        colorScheme="cyan"
        variant={isHighlighted ? "solid" : "outline"}
      >
        Get Started
      </Button>
    </BasePricingTile>
  );
};

export default PricingTile;
